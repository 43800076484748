import { MediaStream, Participant as MindParticipant } from 'mind-sdk-web';

export class Participant {
  private readonly _participant: MindParticipant;

  constructor(participant: MindParticipant) {
    this._participant = participant;
  }

  getId(): string {
    return this._participant.getId();
  }
}
export class MockParticipant {
  private readonly _id: string;
  private readonly _name: string;
  private readonly _isVoiceAssistant: boolean;

  constructor(id: string, name: string, isVoiceAssistant?: boolean) {
    this._id = id;
    this._name = name;
    this._isVoiceAssistant = isVoiceAssistant ?? false;
  }

  getId(): string {
    return this._id;
  }

  getName(): string {
    return this._name;
  }

  getMediaStream(): MediaStream | null {
    return null;
  }

  isStreamingVideo(): boolean {
    return false;
  }

  isStreamingAudio(): boolean {
    return false;
  }

  getSecondaryMediaStream(): MediaStream | null {
    return null;
  }

  isStreamingSecondaryVideo(): boolean {
    return false;
  }

  isVoiceAssistant(): boolean {
    return this._isVoiceAssistant;
  }
}
