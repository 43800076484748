import { BaseLogger } from './base-logger';
import { BaseLogData, LoggerConfig } from './types';

enum LogLevel {
  LOG = 'log',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

type LogData = BaseLogData & {
  level: LogLevel;
  message: string;
  url: string;
  stacktrace?: string;
};

type ConsoleMethod = (message?: unknown, ...optionalParams: unknown[]) => void;
type LoggerConsoleMethods = Record<LogLevel, ConsoleMethod>;

class ConsoleLogger extends BaseLogger<LogData> {
  private originalConsole: LoggerConsoleMethods;
  private unloadHandler: () => void = () => this.cleanup();

  constructor(config: LoggerConfig) {
    super(config);
    this.originalConsole = this.storeOriginalConsoleMethods();
    this.setupConsoleInterceptors();
    this.setupUnloadHandler();
  }

  private storeOriginalConsoleMethods(): LoggerConsoleMethods {
    return Object.values(LogLevel).reduce(
      (acc, type) => ({
        ...acc,
        [type]: console[type],
      }),
      {} as LoggerConsoleMethods
    );
  }

  private setupConsoleInterceptors(): void {
    Object.values(LogLevel).forEach((level) => {
      (console[level] as ConsoleMethod) = (message?: unknown, ...args: unknown[]): void => {
        // Call original method
        this.originalConsole[level](message, ...args);

        // Create and add log data to queue
        this.addToQueue({
          level,
          message: message === undefined ? 'Empty message' : String(message),
          url: window.location.href,
          timestamp: new Date().getTime(),
          stacktrace: (message instanceof Error && message.stack) || new Error().stack?.split('\n').slice(2).join('\n'), // Skip first 2 lines (Error and collectLogData)
          ...this.getCommonData(),
        });
      };
    });
  }

  private setupUnloadHandler(): void {
    window.addEventListener('beforeunload', this.unloadHandler);
    window.addEventListener('unload', this.unloadHandler);
  }

  public override cleanup(): void {
    super.cleanup();
    window.removeEventListener('beforeunload', this.unloadHandler);
    window.removeEventListener('unload', this.unloadHandler);

    Object.values(LogLevel).forEach((type) => {
      (console[type] as ConsoleMethod) = this.originalConsole[type];
    });
  }
}

const setupConsoleLogger = (config: LoggerConfig): (() => void) => {
  const logger = new ConsoleLogger(config);
  return () => logger.cleanup();
};

export default setupConsoleLogger;
