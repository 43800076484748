import setupConsoleLogger from '@/lib/logger/console-logger';
import setupNetworkLogger from '@/lib/logger/network-logger';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

if (import.meta.env.VITE_APP_BROWSER_LOGGER_ENABLED === 'true') {
  const cleanupConsoleLogger = setupConsoleLogger({
    endpoint: import.meta.env.VITE_APP_BROWSER_LOGGER_PATH,
    batchSize: 10,
    flushInterval: 5000,
    debug: false,
  });

  const cleanupNetworkLogger = setupNetworkLogger({
    endpoint: import.meta.env.VITE_APP_BROWSER_LOGGER_PATH,
    batchSize: 10,
    flushInterval: 5000,
    debug: false,
  });

  if (process.env.NODE_ENV !== 'production') {
    // In development, React 18 strict mode will mount/unmount components twice
    window.__LOGGER_CLEANUP = () => {
      cleanupConsoleLogger?.();
      cleanupNetworkLogger?.();
    };
  }
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
