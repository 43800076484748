export type BrowserInfo = {
  userAgent: string;
  language: string;
  platform: string;
  mobile: boolean;
  screenSize: string;
};

export const getBrowserInfo = (): BrowserInfo => ({
  userAgent: navigator.userAgent,
  language: navigator.language,
  platform: navigator.userAgentData?.platform || navigator.platform,
  mobile: navigator.userAgentData?.mobile || /Mobi|Android/i.test(navigator.userAgent),
  screenSize: `${window.screen.width}x${window.screen.height}`,
});

export type LoggerConfig = {
  endpoint: string;
  batchSize?: number;
  flushInterval?: number;
  debug?: boolean;
  maxRetries?: number;
};

export type BaseLogData = BrowserInfo & {
  timestamp: number;
  roomId?: string;
  conferenceId?: string;
  participantId?: string;
  userId?: string;
};

export type NetworkRequestData = BaseLogData & {
  url: string;
  duration: number;
  initiatorType: string;
  transferSize: number;
  status: number | null;
  requestHeaders?: Record<string, string>;
  responseHeaders?: Record<string, string>;
  requestBody?: string;
  responseBody?: string;
  method?: string;
};
