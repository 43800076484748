import { AnonymousUser, User } from '@/model/User.ts';
import { create } from 'zustand';

export interface AuthStore {
  user: User | null;
  setUser: (user: User | null) => void;
  anonymousUser: AnonymousUser | null;
  setAnonymousUser: (user: AnonymousUser | null) => void;
  userLoading: boolean;
  setUserLoading: (loading: boolean) => void;
}

export const useAuthStore = create<AuthStore>((set) => ({
  user: null,
  anonymousUser: null,
  userLoading: true,
  setUser: (user) => set({ user }),
  setAnonymousUser: (user) => set({ anonymousUser: user }),
  setUserLoading: (loading) => set({ userLoading: loading }),
}));
