import { ConferenceNotFoundError } from '@/error/ConferenceNotFoundError';
import { AudioStreamListener } from '@/event/AudioStreamListener.ts';
import { SessionEventListener } from '@/event/SessionEventListener';
import { AnonymousUser } from '@/model/User';
import { AIMessage } from '@/sdk/AIMessage';
import { ConferenceHistoryRecord } from '@/sdk/ConferenceHistoryRecord.ts';
import { IConferenceSDK } from '@/sdk/ConferenceSDK.ts';
import { Participant } from '@/sdk/Participant.ts';
import { TranscriptWord } from '@/sdk/TranscriptWord.ts';
import { AuthService, AuthServiceImpl, getAuthService } from '@/service/AuthService.ts';
import { getDeviceService } from '@/service/DeviceService.ts';
import { useConferenceStore } from '@/store/ConferenceStore';
import { resetAllStores } from '@/store/create-disposable-store.ts';
import { useDeviceStore } from '@/store/DeviceStore';
import { useUIStore } from '@/store/UIStore.ts';
import { Participant as MindParticipant, ParticipantRole, Session } from 'mind-sdk-web';
import { toast } from 'sonner';
import { create } from 'zustand';

export const getConferenceService = (): ConferenceService | undefined =>
  useConferenceServiceStore.getState().conferenceService;
export const useConferenceService = (): ConferenceService | undefined =>
  useConferenceServiceStore((state) => state.conferenceService);
export const initializeConferenceService = (sdk: IConferenceSDK): void => {
  if (!useConferenceServiceStore.getState().conferenceService) {
    useConferenceServiceStore.getState().setConferenceService(new ConferenceServiceImpl(sdk));
  }
};

export interface ConferenceService {
  createConferenceRoom(): Promise<string | null>;
  createParticipant(roomId: string): Promise<Participant>;
  deleteConference(conferenceId: string): Promise<void>;
  createAnonymousParticipant(roomId: string, name: string): Promise<Participant>;
  join(participant: Participant): Promise<void>;
  closeSession(): void;
  isParticipantMe(participantId: string): boolean;
  isParticipantModerator(participantId: string): boolean;
  toggleRecording(): Promise<void>;
  createRecordingToken(conferenceId: string): Promise<string>;
  togglePresenting(): Promise<void>;
  approveJoinRequest(joinRequestId: string): Promise<void>;
  rejectJoinRequest(joinRequestId: string): Promise<void>;
  getConferencesHistory(): Promise<ConferenceHistoryRecord[]>;
  sendAIMessage(roomId: string, aiModel: string, message: string): Promise<void>;
  getAIMessages(roomId: string): Promise<AIMessage[]>;
  getAIModels(): Promise<string[]>;
  getTranscript(conferenceId: string): Promise<TranscriptWord[]>;
  isVoiceAssistantEnabled(conferenceId: string): Promise<boolean>;
  enableVoiceAssistant(conferenceId: string): Promise<void>;
  disableVoiceAssistant(conferenceId: string): Promise<void>;
}

export class ConferenceServiceImpl implements ConferenceService {
  private _sdk: IConferenceSDK;
  private _authService: AuthService;

  constructor(sdk: IConferenceSDK) {
    this._sdk = sdk;
    this._authService = getAuthService() || new AuthServiceImpl(sdk);
  }

  async createConferenceRoom(): Promise<string | null> {
    return await this._sdk.createConferenceRoom();
  }

  async createParticipant(roomId: string): Promise<Participant> {
    const participant = await this._sdk.createParticipant(roomId);
    useConferenceStore.getState().setMindToken(participant.token);
    return participant;
  }

  async deleteConference(conferenceId: string): Promise<void> {
    await this._sdk.deleteConference(conferenceId);
  }

  async createAnonymousParticipant(roomId: string, name: string): Promise<Participant> {
    const anonymousUser = await this._authService.getAnonymousUser();
    let anonymousAuthToken = anonymousUser?.token;

    if (!anonymousAuthToken || anonymousUser?.roomId !== roomId || anonymousUser?.name !== name) {
      anonymousAuthToken = await this._authService.createAnonymousAuthToken(roomId, name);
    }

    const participant = await this._sdk.createAnonymousParticipant(anonymousAuthToken);
    useConferenceStore.getState().setMindToken(participant.token);

    return participant;
  }

  async join(participant: Participant): Promise<void> {
    const session = await this.initializeSession(participant);
    if (!session) {
      throw new ConferenceNotFoundError();
    }

    session.setListener(new SessionEventListener());

    const mindConference = session.getConference();

    const me = mindConference.getMe();
    useConferenceStore.getState().setMe(me);

    const deviceService = getDeviceService();
    if (!deviceService) throw new Error('Device service is not initialized.');
    await deviceService.setupPrimaryMediaStream(me);
    await deviceService.setupSecondaryMediaStream(me);

    const participants = session.getConference()?.getParticipants() || [];
    participants?.forEach((participant) =>
      participant.getMediaStream().addAudioConsumer(new AudioStreamListener(participant.getId()))
    );

    // Recording
    useUIStore.getState().setRecordingEnabled(mindConference.isRecording());

    // Screen presenting
    const presentingParticipant = participants.find((p) => p.isStreamingSecondaryVideo());
    if (presentingParticipant) {
      useUIStore.getState().setPresentingParticipant(presentingParticipant);
    }

    const conferenceStore = useConferenceStore.getState();
    const isVoiceAssistantEnabled = await this.isVoiceAssistantEnabled(participant.conference.id);
    conferenceStore.setConference({ ...participant.conference, isVoiceAssistantEnabled });
    conferenceStore.setSession(session);
    conferenceStore.addParticipants([me, ...participants]);
    if (isVoiceAssistantEnabled) {
      conferenceStore.addVoiceAssistantMockParticipant();
    }

    const isAuthorizedUser = await this._authService.isAuthorized();
    if (isAuthorizedUser) {
      const messages = await this._sdk.getRoomChatMessages(participant.conference.roomId);
      useUIStore.getState().addMessages(messages);
    }
  }

  private async initializeSession(participant: Participant): Promise<Session> {
    if (!participant || !participant.token) {
      throw new Error('Participant or token is not defined');
    }

    const baseUrl = import.meta.env.VITE_APP_BASE_URL;
    const mindAppId = participant.appId;
    const conferenceId = participant.conference.id;
    const conferenceUrl = `${baseUrl}/${mindAppId}/${conferenceId}`;
    return await this._sdk.joinToConference(conferenceUrl, participant.token);
  }

  closeSession(): void {
    const session = useConferenceStore.getState().session;
    if (session) {
      this._sdk.closeSession(session);
    }

    const deviceStore = useDeviceStore.getState();
    deviceStore.microphone?.destroy();
    deviceStore.camera?.destroy();
    deviceStore.screen?.release();

    resetAllStores();
  }

  isParticipantMe(participantId: string): boolean {
    const session = useConferenceStore.getState().session;
    const me = session?.getConference()?.getMe();
    return me?.getId() === participantId;
  }

  isParticipantModerator(participantId: string): boolean {
    const participants = useConferenceStore.getState().participants;
    const participant = participants.find((p) => p.getId() === participantId);
    return participant?.getRole() === ParticipantRole.MODERATOR;
  }

  async toggleRecording(): Promise<void> {
    const conference = useConferenceStore.getState().conference;
    const session = useConferenceStore.getState().session;
    const recordingEnabled = useUIStore.getState().recordingEnabled;
    if (!conference || !session) {
      return;
    }

    try {
      if (recordingEnabled) {
        await this._sdk.stopRecording(conference.roomId);
        session?.fireOnConferenceRecordingStopped(session.getConference());
      } else {
        await this._sdk.startRecording(conference.roomId);
        session?.fireOnConferenceRecordingStarted(session.getConference());
      }
    } catch (e) {
      toast.error(`Can't ${recordingEnabled ? 'stop' : 'start'} recording: ${e.message}`);
    }
  }
  async createRecordingToken(conferenceId: string): Promise<string> {
    return await this._sdk.createRecordingToken(conferenceId);
  }

  async togglePresenting(): Promise<void> {
    const { screenPresentingEnabled, screen, setScreenPresentingEnabled } = useDeviceStore.getState();
    const { unsetPresentingParticipant, setPresentingParticipant } = useUIStore.getState();
    const me: MindParticipant | undefined = useConferenceStore.getState().session?.getConference()?.getMe();

    try {
      if (!screen) return;

      if (screenPresentingEnabled) {
        await screen.release();
        unsetPresentingParticipant();
      } else {
        await screen.acquire();
        if (me) setPresentingParticipant(me);
      }

      setScreenPresentingEnabled(!screenPresentingEnabled);
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        console.log('User denied screen presenting.');
      } else {
        toast.error(`Can't ${screenPresentingEnabled ? 'stop' : 'start'} screen presenting: ${error.message}`);
      }
    }
  }

  async approveJoinRequest(joinRequestId: string): Promise<void> {
    try {
      await this._sdk.approveJoinRequest(joinRequestId);
    } catch (e) {
      toast.error(`Can't approve join request: ${e.message}`);
    }
  }

  async rejectJoinRequest(joinRequestId: string): Promise<void> {
    try {
      await this._sdk.rejectJoinRequest(joinRequestId);
    } catch (e) {
      toast.error(`Can't reject join request: ${e.message}`);
    }
  }

  async getConferencesHistory(): Promise<ConferenceHistoryRecord[]> {
    return await this._sdk.getConferencesHistory();
  }

  async sendAIMessage(conferenceId: string, aiModel: string, message: string): Promise<void> {
    try {
      await this._sdk.sendAIMessage(conferenceId, aiModel, message);
    } catch (e) {
      toast.error(`Can't send AI message: ${e.message}`);
      throw e;
    }
  }

  async getAIMessages(roomId: string): Promise<AIMessage[]> {
    try {
      return await this._sdk.getAIMessages(roomId);
    } catch (e) {
      toast.error(`Can't get AI messages: ${e.message}`);
    }

    return [];
  }

  async getAIModels(): Promise<string[]> {
    try {
      return await this._sdk.getAIModels();
    } catch (e) {
      toast.error(`Can't get AI models: ${e.message}`);
    }

    return [];
  }

  async getTranscript(conferenceId: string): Promise<TranscriptWord[]> {
    try {
      return await this._sdk.getTranscript(conferenceId);
    } catch (e) {
      toast.error(`Can't get transcript: ${e.message}`);
    }

    return [];
  }

  async isVoiceAssistantEnabled(conferenceId: string): Promise<boolean> {
    let user = await this._authService.getUser();
    if (!user) {
      user = await this._authService.getAnonymousUser();
    }

    return await this._sdk.isVoiceAssistantEnabled(
      conferenceId,
      user instanceof AnonymousUser ? user.token : undefined
    );
  }

  async enableVoiceAssistant(conferenceId: string): Promise<void> {
    await this._sdk.enableVoiceAssistant(conferenceId);
    useConferenceStore.getState().addVoiceAssistantMockParticipant();
  }

  async disableVoiceAssistant(conferenceId: string): Promise<void> {
    await this._sdk.disableVoiceAssistant(conferenceId);
    useConferenceStore.getState().removeVoiceAssistantMockParticipant();
  }
}

interface ConferenceServiceStore {
  conferenceService: ConferenceService | undefined;
  setConferenceService: (service: ConferenceService) => void;
}

export const useConferenceServiceStore = create<ConferenceServiceStore>((set) => ({
  conferenceService: undefined,
  setConferenceService: (service: ConferenceService) => set({ conferenceService: service }),
}));
