import { Button } from '@/components/ui/button.tsx';
import { useAuthService } from '@/service/AuthService.ts';

const SignInButton = () => {
  const authService = useAuthService();

  return (
    <Button
      variant="outline"
      onClick={authService?.login}
      className="relative flex h-10 w-24 border-2 border-violet-700 rounded-3xl items-center justify-center overflow-hidden shadow-2xl"
    >
      <span className="relative font-semibold text-md z-10">Sign In</span>
    </Button>
  );
};

export default SignInButton;
