import { useAuthService } from '@/service/AuthService.ts';
import { Outlet } from 'react-router-dom';
import MessageSheet from '@/components/MessageSheet.tsx';
import { useState } from 'react';

const AuthGuard = () => {
  const authService = useAuthService();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  (async () => {
    if (!authService) return;

    const isAuthorized = await authService.isAuthorized();
    if (isAuthorized) {
      setIsAuthorized(true);
    } else {
      authService.login();
      return null;
    }
  })();

  return isAuthorized ? <Outlet /> : <MessageSheet message="Loading..." />;
};

export default AuthGuard;
