import { DeviceService } from '@/service/DeviceService';
import { useConferenceStore } from '@/store/ConferenceStore';
import { DeviceRegistryListener } from 'mind-sdk-web';

export class DeviceRegistryEventListener implements DeviceRegistryListener {
  private readonly _deviceService: DeviceService;

  constructor(deviceService: DeviceService) {
    this._deviceService = deviceService;
  }

  async onDeviceRegistryChanged(): Promise<void> {
    const session = useConferenceStore.getState().session;
    if (session) {
      try {
        await this._deviceService.setupMediaDevices();
      } catch (error) {
        console.error('Error setting up media devices', error);
      }
    }
  }
}
