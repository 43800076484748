import { useAudioAnalizerStore } from '@/store/AudioAnalizerStore.ts';
import { MediaStreamAudioBuffer, MediaStreamAudioConsumer } from 'mind-sdk-web';

export class AudioStreamListener implements MediaStreamAudioConsumer {
  constructor(private readonly participantId: string) {}

  onAudioBuffer(buffer: MediaStreamAudioBuffer): void {
    if (buffer) {
      const audioTrack = buffer.getTrack();
      const stream = new MediaStream([audioTrack]);

      try {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);
        useAudioAnalizerStore.getState().addAnalyzer(this.participantId, analyser);
      } catch (error) {
        console.error('Error creating audio stream context', error);
      }
    }
  }
}
