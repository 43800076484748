export enum UserType {
  ANONYMOUS = 'anonymous',
  AUTHORIZED = 'authorized',
}

export interface IUser {
  type: UserType;
  id: string;
  token: string;
}
export class User implements IUser {
  type = UserType.AUTHORIZED;
  constructor(
    public readonly id: string,
    public readonly email: string,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly token: string
  ) {}

  get fullName(): string {
    return [this.firstName || '', this.lastName || ''].filter(Boolean).join(' ');
  }
}

export class AnonymousUser extends User {
  type = UserType.ANONYMOUS;

  constructor(
    readonly id: string,
    readonly name: string,
    readonly roomId: string,
    readonly exp: number,
    readonly token: string
  ) {
    super(id, '', name, '', token);
  }
}
